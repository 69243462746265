import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import kr from './locales/kr.json';
import jp from './locales/jp.json';
import ru from './locales/ru.json';
import cns from './locales/cns.json';
import cnt from './locales/cnt.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['ko', 'en', 'ru', 'jp', 'cns', 'cnt'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: en,
      },
      ko: {
        common: kr,
      },
      ru: {
        common: ru,
      },
      jp: {
        common: jp,
      },
      cns: {
        common: cns,
      },
      cnt: {
        common: cnt,
      },
    },
  });

export default i18n;
