import { combineReducers } from 'redux';
import authReducer from './authReducer';
import analysisResult from './analysisResult';

const rootReducer = combineReducers({
  authReducer,
  analysisResult,
});

export default rootReducer;
